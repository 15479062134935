import React, { useLayoutEffect, useRef, useState } from "react";
import "./ModalTextInputComponent";

function ModalTextInputComponent({ label, onSubmit, onModalClose }) {
  const inputRef = useRef(null);
  const [value, setValue] = useState("");
  const handleInputChange = (e) => setValue(e.target.value);
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && e.type === "keydown") {
      onSubmit(value);
      onModalClose();
    }
  };

  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button onClick={onModalClose}>X</button>
      </div>
      <label>
        <p style={{ margin: 0, marginBottom: "0.5em" }}>{label}</p>
        <input
          ref={inputRef}
          style={{
            border: "1px solid black",
            width: "100%",
            fontSize: "3rem"
          }}
          type="text"
          value={value}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          onKeyUp={handleKeyPress}
        />
      </label>
    </div>
  );
}

export default ModalTextInputComponent;
