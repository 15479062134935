import React from "react";
import styled from "styled-components";

const CROSSHAIR_THICKNESS = 4;
const CROSSHAIR_OFFSET = CROSSHAIR_THICKNESS / 2;

const WRAPPER_HEIGHT = 120;

const VerticalLine = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -${CROSSHAIR_OFFSET}px;
  height: 100%;
  margin-top: -50%;
  width: ${CROSSHAIR_THICKNESS}px;
  background-color: #000;
`;
const HorizontalLine = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50%;
  margin-top: -${CROSSHAIR_OFFSET}px;
  height: ${CROSSHAIR_THICKNESS}px;
  width: 100%;
  background-color: #000;
`;
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Crosshair = ({ className, children }) => {
  return (
    <div className={className}>
      <Wrapper>
        <VerticalLine />
        <HorizontalLine />
      </Wrapper>
    </div>
  );
};

export default Crosshair;
