const arbitraryTokenFromCredentials = (username, password) =>
  (username.length * 123.5).toString() + password;

export default async ({ username, password, pushToken }) => {
  return fetch(`${process.env.REACT_APP_API_URL}/auth.php`, {
    method: "POST",
    headers: [
      ["content-type", "application/json"],
    ],
    body: JSON.stringify({
      request: "login",
      username,
      password,
    }),
  })
    .then((res) => res.json())
    .then(async ({ error, userID }) => {
      let payload;
      if (error && error?.length > 0) {
        payload = { error };
      } else {
        const token = arbitraryTokenFromCredentials(username, password);
        payload = { userID, username, token, error: "" };
        localStorage.setItem("userToken", token);
        localStorage.setItem("pushToken", pushToken);
        localStorage.setItem("username", username);
        localStorage.setItem("userId", userID.toString());
      }
      return payload;
    })
    .catch((err) => console.error(err));
};
