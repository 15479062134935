import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import L, { divIcon, LatLng } from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { MapContainer, TileLayer, Marker, Polyline } from "react-leaflet";

import getNewGeo from "../helpers/getNewGeo";
import getBearing from "../helpers/getBearing";
import moment from "moment";
import { adjustDateTimezoneDiff } from "app/helpers";
import { throttle } from "lodash";
import getNewPos from "app/helpers/getNewPos";
import MapControlComponent from "./MapControlComponent";
import GeometryUtil from "leaflet-geometryutil";
import getTransportMode from "app/helpers/getTransportMode";
import {
  TRANSPORT_CAR,
  TRANSPORT_WALKING
} from "app/constants/transportValues";
import { TRANSPORT_MODES } from "app/constants";

const ClusterIcon = ({ perc, clusterText }) => {
  return (
    <div>
      <span>{clusterText}</span>
    </div>
  );
};

const MapComponent = ({
  className,
  events,
  currentTime,
  startLocation,
  onUpdateLocation,
  metersIncrementFactor = 1000,
  onToggleShowReadableLocationList,
  onStartLocationModalOpen,
  onTimeTextInputModalOpen,
  onCloseAllModals,
  onTransportValueChange,
  onDisplayEventInfo,
  onUpdateTimeShiftMin,
  transportMode,
  onLocationVisited,
  onDistanceToNext,
  onMapZoomUpdate,
  startLocationSet,
  loggedIn
}) => {
  const setCursorLocationToBeginning = ({ lat, lng }) => {
    console.log("setting start location", [lat, lng]);
    crosshairPos.current = [lat, lng];
    refPos.current = [lat, lng];
    drawCursorPolyline({ drawToNext: true, drawToCurrent: true });
    setStepEndPoint(GeometryUtil.destination(new LatLng(lat, lng), 90, 500));
    changeLocation({ lat, lng }, true);
    initialLocationSet.current = true;
  };

  const getCenterPos = (events) => {
    let result;
    const oldRouteId = localStorage.getItem("oldRouteId");
    const newRouteId = localStorage.getItem("currentRouteId");
    const lsLocation = localStorage.getItem("cursorLocation");

    const isOldRoute = newRouteId === oldRouteId ? true : false;

    if (
      lsLocation &&
      lsLocation.length > 1 &&
      (isOldRoute || events.length === 0)
    ) {
      result = JSON.parse(lsLocation);
    } else if (events.length > 0) {
      result = events[0].location;
    } else {
      result = [45.3999984, 14.7999968];
    }
    return result;
  };

  const getMapZoom = () => {
    let result;
    const defaultZoom = "16";
    // const lsMapZoom = localStorage.getItem("mapZoom") || defaultZoom;
    const lsMapZoom = "16";
    if (lsMapZoom && JSON.parse(lsMapZoom) > 0) {
      result = JSON.parse(lsMapZoom);
    } else {
      result = 13;
    }
    return result;
  };

  const [centerPos, setCenterPos] = useState(getCenterPos(events)); // Default: 'Delnice'
  const [stepStartPoint, setStepStartPoint] = useState(centerPos);
  const [stepEndPoint, setStepEndPoint] = useState(
    GeometryUtil.destination(new LatLng(centerPos[0], centerPos[1]), 90, 500)
  );

  const [mapZoom, setMapZoom] = useState(getMapZoom());
  const mapZoomRef = useRef(getMapZoom());

  const initialLocationSet = useRef(false);
  const crosshairPos = useRef(centerPos);
  const refPos = useRef(centerPos);
  const map = useRef(null);
  const [mapInitialized, setMapInitialized] = useState(false);
  const [key, setKey] = useState(0);

  const lifecycleLoop = useRef(null);

  useEffect(() => {
    return function cleanup() {
      if (lifecycleLoop.current) {
        clearInterval(lifecycleLoop.current);
      }
    };
  }, []);

  useEffect(() => {
    if (mapZoom && mapZoom <= 20 && mapZoom >= 0) {
      onMapZoomUpdate(mapZoom);
    }
  }, [mapZoom]);

  useEffect(() => {
    if (startLocationSet && mapInitialized && startLocation) {
      setCursorLocationToBeginning(startLocation);
    }
  }, [startLocationSet, mapInitialized, events, startLocation]);

  useEffect(() => {
    if (markerClusterRef.current) {
      markerClusterRef.current.refreshClusters();
    }
  }, [events, mapInitialized, startLocation]);

  const handleMapCreated = (elem) => {
    map.current = elem;
    setMapInitialized(true);
  };

  const changeTransportDropdown = (key) => {
    if (key === "KeyH") {
      onTransportValueChange(TRANSPORT_WALKING);
      setStepCount(50);
      // setBaseStepLength(10);
      setMovementLog([]);
      refPos.current = crosshairPos.current;
      prevMovementLogLength.current = 0;
    } else if (key === "KeyJ") {
      onTransportValueChange(TRANSPORT_CAR);
      // setBaseStepLength(100);
      setStepCount(5);
      setMovementLog([]);
      refPos.current = crosshairPos.current;
      prevMovementLogLength.current = 0;
    }
  };

  const changeZoomLevel = (key) => {
    if (key === "Comma") {
      refPos.current = crosshairPos.current;
      setMapZoom((zoom) => (zoom <= 1 ? 1 : zoom - 1));
    } else if (key === "Period") {
      refPos.current = crosshairPos.current;
      setMapZoom((zoom) => (zoom >= 19 ? 19 : zoom + 1));
    }
  };

  const checkNearby = () => {
    const minPoiVisitedRadiusDistance = 10; // in meters
    const cursorPos = new LatLng(
      crosshairPos.current[0],
      crosshairPos.current[1]
    );
    const locationsInRadius = events.map((event) => {
      const eventLocation = new LatLng(event.location[0], event.location[1]);
      const distanceTo = cursorPos.distanceTo(eventLocation);
      return { ...event, distanceTo };
    });
    locationsInRadius.forEach((loc) => {
      if (loc.distanceTo <= minPoiVisitedRadiusDistance && loc.poiID) {
        console.log(
          `Cursor position ${cursorPos} is in radius of: ${
            loc.locationLabel
          } by ${Math.round(loc.distanceTo)} meters`
        );
        onLocationVisited(loc);
      }
    });
  };

  const getBaseStepLength = () => {
    const value = JSON.parse(localStorage.getItem("baseStepLength"));
    if (value) {
      return value;
    } else {
      return getTransportMode() === TRANSPORT_CAR &&
        transportMode === TRANSPORT_CAR
        ? 5
        : 50;
    }
  };

  const [baseStepLength, setBaseStepLength] = useState(getBaseStepLength());
  const [stepCount, setStepCount] = useState(
    getTransportMode() === TRANSPORT_CAR ? 5 : 50
  );
  const [movementLog, setMovementLog] = useState([]);

  const calcTimeShiftMin = (distanceMeters, velocityKmh) => {
    return (distanceMeters / velocityKmh) * 60;
  };

  useEffect(() => {
    localStorage.setItem("baseStepLength", JSON.stringify(baseStepLength));
    const velocity = TRANSPORT_MODES.find((m) => m.value === transportMode)
      .velocity;
    const min = calcTimeShiftMin(baseStepLength, velocity * 1000);
    console.log('min,',min)
    onUpdateTimeShiftMin(min);
  }, [baseStepLength, transportMode]);

  const moveCursor = useCallback(
    (synthetic = true) => {
      const bearing = getBearing(pressedKeyMap.current);
      const userId = localStorage.getItem("userId");
      console.log('bearing', userId)
      if (bearing && userId) {
        const movementObject = {
          timestamp: new Date().getTime(),
          bearing
        };
        if (mapZoomRef.current === mapZoom) {
          // Map zoom not changed, append item to movements array
          setMovementLog((previous) => [...previous, movementObject]);
        } else {
          // Map zoom changed, reset movement log and set its previous length to 0
          setMovementLog([movementObject]);
          prevMovementLogLength.current = 0;
        }
        mapZoomRef.current = mapZoom;
      }
      checkNearby();
    },
    [events, metersIncrementFactor, baseStepLength, mapZoom, mapZoomRef]
  );

  const prevMovementLogLength = useRef(0);
  useEffect(() => {
    console.log("movementLog", movementLog);
    if (map.current && movementLog.length > prevMovementLogLength.current) {
      const nextPosition = getNewPos(
        refPos.current,
        movementLog,
        baseStepLength
      );
      const [lat, lng] = nextPosition;
      // if (lat === 45.346106954379096 && lng === 14.377554656003314) {
      //   console.log("Cursor is on initial point! ✅");
      // }
      crosshairPos.current = nextPosition;
      changeLocation({ lat, lng }, false);
      drawCursorPolyline({ drawToNext: true, drawToCurrent: true });
      prevMovementLogLength.current = movementLog.length;
    } else if (map.current) {
      const [lat, lng] = crosshairPos.current;
      changeLocation({ lat, lng });
    }
  }, [movementLog, baseStepLength, prevMovementLogLength]);

  const keyComboListener = () => {
    const keyMap = pressedKeyMap.current;
    const pressedKeyValues = Object.values(keyMap);
    const pressedKeysMap = Object.keys(keyMap).filter(
      (el, ind) => pressedKeyValues[ind]
    );
    if (
      (pressedKeysMap.includes("ShiftLeft") ||
        pressedKeysMap.includes("ShiftRight")) &&
      pressedKeysMap.includes("Enter")
    ) {
      // console.log("SHIFT + ENTER key combo detected!");
      pressedKeyMap.current["Enter"] = false;
      pressedKeyMap.current["ShiftLeft"] = false;
      pressedKeyMap.current["ShiftRight"] = false;

      onStartLocationModalOpen();
    } else if (pressedKeysMap.includes("Enter")) {
      onTimeTextInputModalOpen();
      pressedKeyMap.current["Enter"] = false;
      // console.log("ENTER keypress detected!");
    } else if (pressedKeysMap.includes("Escape")) {
      onCloseAllModals();
      pressedKeyMap.current["Escape"] = false;
      // console.log("Escape keypress detected!");
    } else if (pressedKeysMap.includes("KeyL")) {
      onToggleShowReadableLocationList();
    }
  };

  const pressedKeyMap = useRef({});
  const handleKeyPress = (e) => {
    e.preventDefault();
    e.stopPropagation();
    changeTransportDropdown(e.code);
    changeZoomLevel(e.code);
    const value = (pressedKeyMap.current[e.code] = e.type == "keydown");
    pressedKeyMap.current[e.code] = value;
    if (!value) {
      const pressedKeyIndex = Object.values(pressedKeyMap.current).findIndex(
        (k) => k
      );
      const key = Object.keys(pressedKeyMap.current)[pressedKeyIndex];
      wrapperRef.current.focus();
    }
    if (events.length > 0) {
      moveCursor(false);
    }
    keyComboListener();
  };

  const changeLocation = ({ lat, lng }, synthetic = true) => {
    localStorage.setItem("cursorLocation", JSON.stringify([lat, lng]));
    map.current.panTo(new L.LatLng(lat, lng));
    onUpdateLocation({ lat, lng }, synthetic);
    setStepStartPoint([lat, lng]);
  };

  const changeLocationThrottled = useCallback(
    throttle(changeLocation, 500),
    []
  );

  const textStyle = { margin: "0 3rem" };

  const [pastEvents, setPastEvents] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [futureEvents, setFutureEvents] = useState([]);

  useEffect(() => {
    if (currentTime && events && events.length > 0) {
      const eventsNoStartEnd = events.filter(
        (event) =>
          event.locationLabel !== "End" && event.locationLabel !== "Start"
      );
      console.log(eventsNoStartEnd);
      const currTimeEpoch = adjustDateTimezoneDiff(currentTime.getTime(), true);
      const pastEvts = eventsNoStartEnd.filter((evt) => {
        const evtDOY = moment(
          adjustDateTimezoneDiff(parseInt(evt.beginsAtEpoch), true)
        ).dayOfYear();
        const currDOY = moment(
          adjustDateTimezoneDiff(parseInt(currTimeEpoch), true)
        ).dayOfYear();
        return evtDOY < currDOY;
      });
      const currEvts = eventsNoStartEnd.filter((evt) => {
        const evtDOY = moment(
          adjustDateTimezoneDiff(parseInt(evt.beginsAtEpoch), true)
        ).dayOfYear();
        const currDOY = moment(
          adjustDateTimezoneDiff(parseInt(currTimeEpoch), true)
        ).dayOfYear();
        return evtDOY === currDOY;
      });
      const futureEvts = eventsNoStartEnd.filter((evt) => {
        const evtDOY = moment(
          adjustDateTimezoneDiff(parseInt(evt.beginsAtEpoch), true)
        ).dayOfYear();
        const currDOY = moment(
          adjustDateTimezoneDiff(parseInt(currTimeEpoch), true)
        ).dayOfYear();
        return evtDOY > currDOY;
      });
      setPastEvents(pastEvts);
      setCurrentEvents(currEvts);
      setFutureEvents(futureEvts);
    }
    drawCursorPolyline({ drawToNext: true, drawToCurrent: true });
    // if (map.current) {
    //   changeLocationThrottled(
    //     { lat: crosshairPos.current[0], lng: crosshairPos.current[1] },
    //     true
    //   );
    // }
  }, [events, currentTime]);

  // TODO: Move to backend & simplify
  const getIsEventCurrent = useCallback(
    (evts, idx) => {
      const lastEvent = evts[evts.length - 1];
      const lastEventIsNext = lastEvent.isNext ? true : false;
      const timePastLastEvent =
        currentTime.getTime() >= parseInt(lastEvent.beginsAtEpoch)
          ? true
          : false;

      if (idx === evts.length - 1 && lastEventIsNext && timePastLastEvent) {
        return true;
      } else {
        const lastIndexNo = evts.length - 1;
        return idx !== lastIndexNo &&
          idx !== lastIndexNo - 1 &&
          evts[idx + 1] &&
          evts[idx + 1].isNext
          ? true
          : false;
      }
    },
    [currentTime]
  );

  const getIconText = (idx, length) => {
    if (idx === 0) {
      return "S";
    } else if (idx === length - 1) {
      return "E";
    } else {
      return idx;
    }
  };

  const getIcon = (evt, idx, arr) => {
    const markerColors = { today: "#01a6ff", past: "#808080", future: "#000" };

    const eventIsCurrent = getIsEventCurrent(arr, idx);
    let markerBgColor = eventIsCurrent ? "yellow" : "#01a6ff";
    const iconText = getIconText(idx, arr.length);
    const iconTextColor = eventIsCurrent ? "#000" : "#fff";

    const eventIsToday = currentEvents.find((e) => e._id === evt._id);
    const eventPassed = pastEvents.find((e) => e._id === evt._id);
    const eventInFuture = futureEvents.find((e) => e._id === evt._id);

    if (eventIsCurrent) {
      const cursorPos = new LatLng(
        crosshairPos.current[0],
        crosshairPos.current[1]
      );
      const eventLocation = new LatLng(evt.location[0], evt.location[1]);
      const distanceTo = cursorPos.distanceTo(eventLocation);
      onDistanceToNext(distanceTo);
    }

    if (eventIsToday && !eventIsCurrent) {
      markerBgColor = markerColors.today;
    } else if (eventInFuture) {
      markerBgColor = markerColors.future;
    } else if (eventPassed) {
      markerBgColor = markerColors.past;
    }

    const markerHtmlStyles = `
      background-color: ${markerBgColor};
      color: #fff;
      width: 3rem;
      height: 3rem;
      display: block;
      left: -1.5rem;
      top: -1.5rem;
      position: relative;
      border-radius: 3rem 3rem 0;
      transform: rotate(45deg);
      border: 1px solid #FFFFFF`;

    return divIcon({
      className: "itn-pin",
      iconAnchor: [0, 36],
      popupAnchor: [0, -36],
      html: `<span style="${markerHtmlStyles}">
      <div style="top: -5px;
      position: relative;
      text-align: center;
      transform: rotate(317deg);
      font-size: 2.5rem;
      color: ${iconTextColor}">
      ${iconText}
      </div></span>`
    });
  };

  const clusterIconCreateFn = (cluster) => {
    const childCount = cluster.getChildCount();
    const childMarkers = cluster.getAllChildMarkers();
    const lowestOrdinal = childMarkers
      .map((m) => m.options.eventOrdinal)
      .sort((a, b) => a - b)[0];
    const nextOrdinal = childMarkers
      .map((m) => m.options.nextOrdinal)
      .filter((o) => o)[0];
    // const lowestOrdinal = ordinals[ordinals.length - 1];
    let c = " marker-cluster-";
    if (childCount < 10) {
      c += "small";
    } else if (childCount < 100) {
      c += "medium";
    } else {
      c += "large";
    }

    let hasNextMarker = childMarkers.reduce((acc, curr) => {
      if (curr.options.isNext || acc) {
        return true;
      } else {
        return acc;
      }
    }, false);

    const iconColor = hasNextMarker ? "color-black" : "";

    return new L.DivIcon({
      html: ReactDOMServer.renderToString(
        <ClusterIcon perc={key} clusterText={nextOrdinal || ""} />
      ),
      className: `marker-cluster ${iconColor}` + c,
      iconSize: new L.Point(40, 40)
    });
  };

  const markerClusterRef = useRef();

  const [nextEventCursorPolyline, setNextEventCursorPolyline] = useState([]);
  const [currentEventCursorPolyline, setCurrentEventCursorPolyline] = useState(
    []
  );

  const drawCursorPolyline = useCallback(
    ({ drawToCurrent, drawToNext }) => {
      const nextEventIdx = events.findIndex((e) => e.isNext);
      const nextEvent = events[nextEventIdx];
      // TODO: Move to backend & simplify
      const currentEvent =
        nextEventIdx === events.length - 1 || nextEventIdx === 0
          ? events[nextEventIdx]
          : events[nextEventIdx - 1];

      if (
        drawToCurrent &&
        map.current &&
        currentEvent &&
        currentEvent.locationLabel !== "Start" &&
        currentEvent.locationLabel !== "End"
      ) {
        // All activities except 'Start'
        // console.log(
        //   "1: setting current event cursor polyline",
        //   crosshairPos.current,
        //   currentEvent.location
        // );
        setCurrentEventCursorPolyline([
          crosshairPos.current,
          currentEvent.location
        ]);
      } else if (
        // 'Start' activity
        drawToCurrent &&
        map.current &&
        currentEvent &&
        currentEvent.locationLabel === "Start"
      ) {
        // console.log(
        //   "2: setting current event cursor polyline",
        //   crosshairPos.current,
        //   currentEvent.location
        // );
        setCurrentEventCursorPolyline([
          crosshairPos.current,
          nextEvent.location
        ]);
      } else if (
        // After 'End' activity is over, hide polyline
        currentEvent?.locationLabel === "End" &&
        currentTime > parseInt(currentEvent?.endsAtEpoch) &&
        !nextEvent
      ) {
        setCurrentEventCursorPolyline([]);
      }
      if (drawToNext && nextEvent) {
        setNextEventCursorPolyline([crosshairPos.current, nextEvent.location]);
      }
    },
    [events, currentTime]
  );

  const wrapperRef = useRef(null);

  const onMouseOut = () => {
    onDisplayEventInfo(undefined);
  };

  const onMouseOver = (evt) => {
    const beginsAtDate = moment(
      adjustDateTimezoneDiff(new Date(parseInt(evt.beginsAtEpoch)))
    ).format("DD.MM.YYYY");
    const beginsAtTime = moment(
      adjustDateTimezoneDiff(new Date(parseInt(evt.beginsAtEpoch)))
    ).format("HH:mm");

    const evtInfo = {
      day: evt.day,
      beginsAtDate,
      beginsAtTime,
      duration: evt.duration || "N/A",
      locationLabel: evt.locationLabel,
      name: evt.name
    };
    onDisplayEventInfo(evtInfo);
  };

  const drawMarkers = (idx, arr, evt) => {
    return idx !== 0 && idx !== arr.length - 1 ? (
      <Marker
        icon={getIcon(evt, idx, arr)}
        key={idx + Math.random() * 100}
        tabIndex={0}
        eventOrdinal={idx}
        nextOrdinal={evt.isNext ? idx : null}
        isNext={evt.isNext}
        position={evt.location}
        eventHandlers={{
          mouseover: () => onMouseOver(evt),
          mouseout: onMouseOut
        }}
      ></Marker>
    ) : (
      <></>
    );
  };

  return (
    <div
      ref={wrapperRef}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyPress}
      onKeyUp={handleKeyPress}
    >
      <MapContainer
        whenCreated={handleMapCreated}
        dragging={false}
        keyboard={false}
        inertia={false}
        className={className}
        center={centerPos}
        zoom={mapZoom}
        scrollWheelZoom={false}
      >
        {/* See https://react-leaflet.js.org/docs/start-introduction#react-context  */}
        <MapControlComponent
          mapZoom={mapZoom}
          mapInitialized={mapInitialized}
          onSetNewPoint2={setStepEndPoint}
          stepCount={stepCount}
          onSetStepLength={setBaseStepLength}
          stepStartPoint={stepStartPoint}
          stepEndPoint={stepEndPoint}
        />
        {loggedIn && (
          <>
            <Polyline
              positions={currentEvents.map((evt) => evt.location)}
              color="blue"
            />{" "}
            {/* Current events */}
            <Polyline
              positions={pastEvents.map((evt) => evt.location)}
              color="grey"
            />{" "}
            {/* Past events */}
            <Polyline
              positions={futureEvents.map((evt) => evt.location)}
              color="#000"
            />{" "}
            {/* Future events */}
            <Polyline positions={currentEventCursorPolyline} color="yellow" />
          </>
        )}
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        ></TileLayer>
        {/* <MarkerClusterGroup
          iconCreateFunction={clusterIconCreateFn}
          ref={markerClusterRef}
        > */}
        {events && events.map((evt, idx, arr) => drawMarkers(idx, arr, evt))}
        {/* <Marker
          key={Math.random() * 100}
          tabIndex={0}
          position={stepStartPoint}
        ></Marker>
        <Marker
          key={Math.random() * 100}
          tabIndex={0}
          position={stepEndPoint || stepStartPoint}
        ></Marker> */}
        {/* </MarkerClusterGroup> */}
      </MapContainer>
    </div>
  );
};

export default MapComponent;
