import React, { forwardRef, useEffect, useState } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { adjustDateTimezoneDiff } from "app/helpers";

// @ts-ignore
const Clock = ({ className, dateTime, onDateTimeChange, events, children }) => {
  const [displayTime, setDisplayTime] = useState(
    adjustDateTimezoneDiff(dateTime)
  );
  const [dayOfItinerary, setDayOfItinerary] = useState(1);
  const CustomInput = forwardRef(
    // @ts-ignore
    ({ value, onClick }, ref) => (
      <div
        style={{ cursor: "pointer" }}
        className="custom-input"
        onClick={onClick}
        ref={ref}
      >
        <p style={{ margin: "5px 0" }}>
          {moment(displayTime).format("DD.MM.YYYY")}
        </p>
        <p style={{ margin: "3px 0" }}>(Day no. {dayOfItinerary}) &nbsp;</p>
        <p style={{ margin: "3px 0" }}>
          &nbsp; 🕘 {moment(displayTime).format("HH:mm")} h
        </p>
      </div>
    )
  );
  CustomInput.displayName = "CustomInput";

  useEffect(() => {
    if (events && dateTime) {
      const currentDateTime = dateTime.getTime();
      events.forEach((evt) => {
        const startAt = parseInt(evt.beginsAtEpoch);
        const endAt = parseInt(evt.endsAtEpoch);
        if (currentDateTime >= startAt && currentDateTime <= endAt) {
          setDayOfItinerary(evt.day);
        }
      });
    }
  }, [events]);

  useEffect(() => {
    setDisplayTime(adjustDateTimezoneDiff(dateTime));
  }, [dateTime]);

  return (
    <div className={className}>
      <DatePicker
        selected={dateTime}
        // @ts-ignore
        onChange={(date) => onDateTimeChange(date)}
        customInput={<CustomInput />}
        showTimeInput
        // @ts-ignore
        // customTimeInput={<CustomTimeInput />}
      />
    </div>
  );
};

export default Clock;
