export default (keypress) => {
  const k = keypress;
  const topKeyCombo = k.ArrowUp || k.Numpad8 || k.KeyW;
  const topLeftKeyCombo = (k.ArrowLeft && k.ArrowUp) || k.Numpad7 || k.KeyQ;
  const leftKeyCombo = k.ArrowLeft || k.Numpad4 || k.KeyA;
  const bottomLeftKeyCombo =
    (k.ArrowDown && k.ArrowLeft) || k.Numpad1 || k.KeyZ || k.KeyY;
  const bottomKeyCombo = k.ArrowDown || k.Numpad2 || k.KeyS;
  const bottomRightKeyCombo =
    (k.ArrowRight && k.ArrowDown) || k.Numpad3 || k.KeyX;
  const rightKeyCombo = k.ArrowRight || k.Numpad6 || k.KeyD;
  const topRightKeyCombo = (k.ArrowUp && k.ArrowRight) || k.Numpad9 || k.KeyE;
  let bearing;
  if (topLeftKeyCombo) {
    bearing = 360 - (360 - 270) / 2;
  } else if (topRightKeyCombo) {
    bearing = 90 - 90 / 2;
  } else if (bottomRightKeyCombo) {
    bearing = 180 - (180 - 90) / 2;
  } else if (bottomLeftKeyCombo) {
    bearing = 270 - (270 - 180) / 2;
  } else if (topKeyCombo) {
    bearing = 360;
  } else if (rightKeyCombo) {
    bearing = 90;
  } else if (bottomKeyCombo) {
    bearing = 180;
  } else if (leftKeyCombo) {
    bearing = 270;
  }
  return bearing;
};
