import getNewGeo from "./getNewGeo";

export default (
  initialLatLng = [45.346106954379096, 14.377554656003314],
  movementsList,
  stepLength
) => {
  let x = movementsList
    .filter((m) => m.bearing === 90 || m.bearing === 270)
    .reduce((acc, curr) => {
      const res = curr.bearing === 90 ? 1 : -1;
      return acc + res;
    }, 0);
  let y = movementsList
    .filter((m) => m.bearing === 360 || m.bearing === 180)
    .reduce((acc, curr) => {
      const res = curr.bearing === 360 ? 1 : -1;
      return acc + res;
    }, 0);
  const firstDiagonal = movementsList
    .filter((m) => m.bearing === 45 || m.bearing === 225)
    .reduce((acc, curr) => {
      const res = curr.bearing === 45 ? 1 : -1;
      return acc + res;
    }, 0);
  const secondDiagonal = movementsList
    .filter((m) => m.bearing === 135 || m.bearing === 315)
    .reduce((acc, curr) => {
      const res = curr.bearing === 315 ? 1 : -1;
      return acc + res;
    }, 0);

  x = x + firstDiagonal - secondDiagonal;
  y = y + firstDiagonal + secondDiagonal;

  // const firstCoord = `${x},${y}`;
  // const secondCoord = `${firstDiagonal},${secondDiagonal}`;

  const resultX = -x + firstDiagonal * Math.cos(toRad(45));
  const resultY = y + secondDiagonal * Math.sin(toRad(45));

  const bearing = getCartesianBearing(
    0,
    0,
    resultX * stepLength,
    resultY * stepLength
  );
  const distance = Math.hypot(resultX * stepLength, resultY * stepLength);

  const nextPos = getNewGeo(initialLatLng, distance, bearing);
  return nextPos;
};

const toRad = (deg) => (deg * Math.PI) / 180;

const getCartesianBearing = (x1, y1, x2, y2) => {
  const TWOPI = Math.PI * 2;
  const RAD2DEG = 180 / Math.PI;
  let theta = Math.atan2(y2 - y1, x2 - x1);
  if (theta < 0.0) theta += TWOPI;
  return 270 + RAD2DEG * theta;
};
