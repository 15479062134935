import React from "react";
import "./ReadableLocationList.scss";

const getActivityPrefix = (activity, index) => {
  if (activity.locationLabel === "Start" || activity.locationLabel === "End") {
    return "";
  } else {
    return `${index}.`;
  }
};

function ReadableLocationList({ data, display }) {
  return (
    <>
      {display && data && data.length > 0 && (
        <div className="readable-location-list-container">
          {data.map((evt, idx) => (
            <p key={idx}>
              {getActivityPrefix(evt, idx)} {evt.locationLabel}
            </p>
          ))}
        </div>
      )}
    </>
  );
}

export default ReadableLocationList;
