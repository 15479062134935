const { Point } = require("leaflet");
const { useState, useEffect, useCallback, useRef } = require("react");
const { useMapEvents, useMap } = require("react-leaflet");

const MapControlComponent = ({
  mapZoom = 16,
  stepCount = 5,
  mapInitialized,
  onSetNewPoint2,
  stepStartPoint,
  stepEndPoint,
  onSetStepLength
}) => {
  const [point2, setPoint2] = useState(null);
  const [baseDistanceBetween, setBaseDistanceBetween] = useState(null);
  const [oldDistance, setOldDistance] = useState(0);
  const [distanceDiff, setDistanceDiff] = useState(0);

  const calculatePointDistance = useCallback(() => {
    console.log("stepStartPoint, stepEndPoint", stepStartPoint, stepEndPoint);
    const _point1 = map.latLngToLayerPoint(stepStartPoint);
    const _point2 = map.latLngToLayerPoint(stepEndPoint);

    const distanceBetween = Math.floor(_point1.distanceTo(_point2));

    console.log("New distance betw. pts:", distanceBetween);
    if (oldDistance > 0) {
      console.log(`Old distance exists (${oldDistance}).`);
      const newDistanceDiff = distanceBetween - oldDistance;
      setDistanceDiff(newDistanceDiff);
      console.log("New distance diff between points is:", newDistanceDiff);
    } else {
      console.log("Old distance is null");
    }
    setOldDistance(distanceBetween);
    return {
      _point1,
      _point2,
      distanceBetween
    };
  }, [stepStartPoint, stepEndPoint]);

  const getStepCount = useCallback(() => stepCount, [stepCount]);

  const point2YRef = useRef(null);

  const getPoint2 = () => {
    const _stepCount = getStepCount();
    localStorage.setItem("mapZoom", JSON.stringify(mapEvents.getZoom()));
    const pointMeta = calculatePointDistance();

    const roundedMetaDistanceBetween = pointMeta.distanceBetween;
    const roundedBaseDistanceBetween =
      baseDistanceBetween || roundedMetaDistanceBetween;

    if (!baseDistanceBetween) {
      setBaseDistanceBetween(roundedMetaDistanceBetween);
    }

    if (
      baseDistanceBetween
      // &&
      // roundedMetaDistanceBetween !== roundedBaseDistanceBetween
    ) {
      console.log(
        "setting point 2",
        roundedMetaDistanceBetween,
        roundedBaseDistanceBetween
      );
      const newPoint2 = new Point(
        pointMeta._point1.x + roundedBaseDistanceBetween,
        point2YRef.current || pointMeta._point2.y
      );

      if (!point2YRef.current) {
        point2YRef.current = newPoint2.y;
      }

      const cartesianDistance = pointMeta._point1.distanceTo(newPoint2);
      const metricDistance = map.distance(
        map.layerPointToLatLng(pointMeta._point1),
        map.layerPointToLatLng(newPoint2)
      );
      onSetStepLength(metricDistance / _stepCount); // set step length to distance between point1 and point2

      setPoint2(newPoint2);
      onSetNewPoint2(map.layerPointToLatLng(newPoint2));
    }
  };

  useEffect(() => {
    getPoint2();
  }, [stepCount]);

  useEffect(() => {
    if (mapInitialized) {
      getPoint2();
    }
  }, [mapInitialized]);

  const mapEvents = useMapEvents({
    zoomend: () => {
      getPoint2();
    },
    load: () => {
      console.log("MAP LOADED! ZOOM LEVEL IS:", mapEvents.getZoom());
      getPoint2();
    }
  });

  const map = useMap();

  useEffect(() => {
    map.setZoom(mapZoom);
  }, [mapZoom]);

  // useEffect(() => {
  //   console.log("distanceDiff", distanceDiff);
  // }, [distanceDiff, point1, point2]);

  return null;
};

export default MapControlComponent;
