import React from "react";

const LoginIndicator = ({ className, loggedIn, onClick, children }) => {
  const text = loggedIn ? "Sign out" : "Sign in";
  return (
    <div className={className} onClick={(e) => (onClick ? onClick(e) : null)}>
      <h3>{text}</h3>
    </div>
  );
};

export default LoginIndicator;
