const zoomLevels = [
  { level: 0, multiplier: 156412 },
  { level: 1, multiplier: 78206 },
  { level: 2, multiplier: 39103 },
  { level: 3, multiplier: 19551 },
  { level: 4, multiplier: 9776 },
  { level: 5, multiplier: 4888 },
  { level: 6, multiplier: 2444 },
  { level: 7, multiplier: 1222 },
  { level: 8, multiplier: 610.984 },
  { level: 9, multiplier: 305.492 },
  { level: 10, multiplier: 152.746 },
  { level: 11, multiplier: 76.373 },
  { level: 12, multiplier: 38.187 },
  { level: 13, multiplier: 19.093 },
  { level: 14, multiplier: 9.547 },
  { level: 15, multiplier: 4.773 },
  { level: 16, multiplier: 2.387 },
  { level: 17, multiplier: 1 },
  { level: 18, multiplier: 1 },
  { level: 19, multiplier: 1 },
  { level: 20, multiplier: 1 }
];

export default zoomLevels;
