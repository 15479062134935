export default (center, distance, bearing) => {
  let x = null;

  const R = 6371000; // meters , earth Radius approx
  const PI = Math.PI;
  const RADIANS = PI / 180;
  const DEGREES = 180 / PI;

  let lat2;
  let lon2;

  const lat1 = center[0] * RADIANS;
  const lon1 = center[1] * RADIANS;
  const radbear = bearing * RADIANS;

  lat2 = Math.asin(
    Math.sin(lat1) * Math.cos(distance / R) +
      Math.cos(lat1) * Math.sin(distance / R) * Math.cos(radbear)
  );
  lon2 =
    lon1 +
    Math.atan2(
      Math.sin(radbear) * Math.sin(distance / R) * Math.cos(lat1),
      Math.cos(distance / R) - Math.sin(lat1) * Math.sin(lat2)
    );

  x = [lat2 * DEGREES, lon2 * DEGREES];

  return x;
};
