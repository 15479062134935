const TIME_DIFF = JSON.parse(process.env.REACT_APP_ADJUST_TIMEZONE)
  ? -(2 * 60 * 60 * 1000)
  : 0;

export const adjustDateTimezoneDiff = (date, isEpoch = false) => {
  if (date && !isEpoch) {
    const inputEpoch = date.getTime();
    const resultEpoch = inputEpoch + TIME_DIFF;
    return new Date(resultEpoch);
  } else if (date && isEpoch) {
    return date + TIME_DIFF;
  } else {
    return null;
  }
};

export const adjustOneActivityTimezoneDiff = (activity) => {
  if (activity) {
    const newBeginsAt = parseInt(activity.beginsAtEpoch) + TIME_DIFF;
    const newEndsAt = parseInt(activity.endsAtEpoch) + TIME_DIFF;

    return { ...activity, beginsAtEpoch: newBeginsAt, endsAtEpoch: newEndsAt };
  } else {
    return null;
  }
};

export const adjustForTimezoneDiff = (_events) => {
  return _events.map((evt) => {
    // Change this according to server timezone
    const newBeginsAt = parseInt(evt.beginsAtEpoch) + TIME_DIFF;
    const newEndsAt = parseInt(evt.endsAtEpoch) + TIME_DIFF;
    return { ...evt, beginsAtEpoch: newBeginsAt, endsAtEpoch: newEndsAt };
  });
};
