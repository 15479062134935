import React, { useEffect, useState } from "react";
import "./EventInfoComponent.scss";

function EventInfoContainer({ eventInfo }) {
  const { day, beginsAtTime, beginsAtDate, duration, locationLabel, name } =
    eventInfo || {};

  const [show, setShow] = useState(false);
  useEffect(() => {
    if (eventInfo) setShow(true);
  }, [eventInfo]);
  return (
    <>
      {eventInfo && show && (
        <div className="event-info-container" onClick={() => setShow(false)}>
          <p>Location name: {locationLabel}</p>
          <p>Type: {name}</p>
          <p>Day of activity: {day}</p>
          <p>Starts at time: {beginsAtTime}</p>
          <p>Starts at date: {beginsAtDate}</p>
          <p>Lasts for: {duration}</p>
        </div>
      )}
    </>
  );
}

export default EventInfoContainer;
