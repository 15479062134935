import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState
} from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import Modal from "react-modal";
import styled from "styled-components";
import "../css/App.css";

import MapComponent from "./components/Map";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Clock from "./components/Clock";
import LoginIndicator from "./components/LoginIndicator";
import Crosshair from "./components/Crosshair";
import TransportDropdown from "./components/TransportDropdown";

import { io } from "socket.io-client";
import { throttle } from "lodash";
import login from "./services/Login";
import logout from "./services/Logout";
import { TRANSPORT_MODES } from "./constants";
import EventInfoComponent from "./components/EventInfoComponent/EventInfoComponent";
import DistanceInfoComponent from "./components/DistanceInfoComponent/DistanceInfoComponent";
import ModalTextInputComponent from "./components/ModalTextInputComponent/ModalTextInputComponent";
import zoomLevels from "./constants/zoomLevels";
import ReadableLocationList from "./components/ReadableLocationList/ReadableLocationList";
import moment from "moment-timezone";

const MAIN_CONTENT_WIDTH = 100;
const MAIN_CONTENT_HEIGHT = 100;
const NAVBAR_HEIGHT = 10;
const SIDEBAR_WIDTH = 100 - MAIN_CONTENT_WIDTH;

const CROSSHAIR_CONTAINER_SIZE = 60;
const CROSSHAIR_CONTAINER_OFFSET = CROSSHAIR_CONTAINER_SIZE / 2;

const modalStyles = {
  overlay: {
    zIndex: 1000
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

const textInputModalStyles = {
  ...modalStyles,
  overlay: {
    ...modalStyles.overlay,
    backgroundColor: "rgba(0, 0, 0, 0.8)"
  },
  content: {
    ...modalStyles.content,
    width: "90vw"
  }
};

function App() {
  Modal.setAppElement("#root");

  const getPersistedDateTime = () => {
    const persistedDateTime = localStorage.getItem("clienttime");
    return persistedDateTime ? new Date(parseInt(persistedDateTime)) : null;
  };

  const subtitle = useRef(null);
  const visitedLocationId = useRef(null);
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("userToken") ? true : false
  );
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [pushToken, setPushToken] = useState("");

  const zoomMultiplier = useRef(1);

  const DEFAULT_TRANSPORT_MODE = localStorage.getItem("transportMode")
    ? JSON.parse(localStorage.getItem("transportMode"))
    : TRANSPORT_MODES[0];
  const [transportValue, setTransportValue] = useState(
    DEFAULT_TRANSPORT_MODE.value
  ); // can be 'car' or 'walking' for now
  const [metersIncrementFactor, setMetersIncrementFactor] = useState(
    DEFAULT_TRANSPORT_MODE.velocity
  );
  const [timeIncrementFactor, setTimeIncrementFactor] = useState(
    DEFAULT_TRANSPORT_MODE.velocity
  );

  const [startLocation, setStartLocation] = useState(null);
  const [newRouteLoaded, setNewRouteLoaded] = useState(false);
  const [startLocationSet, setStartLocationSet] = useState(false);
  const [clientDateTime, setClientDateTime] = useState(
    getPersistedDateTime() || new Date()
  );
  const clientDateTimeRef = useRef(getPersistedDateTime() || new Date());
  const [eventInfo, setEventInfo] = useState(null);
  const [showReadableLocationList, setShowReadableLocationList] = useState(
    false
  );
  const [distanceToNextActivity, setDistanceToNextActivity] = useState(null);

  // Modals
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [startLocationModalActive, setStartLocationModalActive] = useState(
    false
  );
  const [timeTextInputModalActive, setTimeTextInputModalActive] = useState(
    false
  );

  useEffect(() => {
    if (!loggedIn) {
      setModalIsOpen(true);
    }
  }, []);

  useEffect(() => {
    const velocity = TRANSPORT_MODES.find((m) => m.value === transportValue)
      .velocity;
    setMetersIncrementFactor(velocity * zoomMultiplier.current);
  }, [transportValue]);

  const handleStartLocationModalOpen = () => {
    setStartLocationModalActive(true);
  };
  const handleTimeTextInputModalOpen = () => {
    setTimeTextInputModalActive(true);
  };
  const handleCloseAllModals = () => {
    setModalIsOpen(false);
    setStartLocationModalActive(false);
    setTimeTextInputModalActive(false);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const closeStartLocationModal = () => {
    setStartLocationModalActive(false);
  };
  const closeTimeTextInputModal = () => {
    setTimeTextInputModalActive(false);
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.current.style.color = "#f00";
  }

  // TODO: Change logic? Next event is the one which is in future AND has not been visited.
  const calculateNextEvent = (_events, clienttime) => {
    _events.forEach((evt) => {
      if (evt.isNext) {
        delete evt.isNext;
      }
    });
    const timelineEvents = _events.map((evt) => {
      const { _id } = evt;
      const timeDiff = clienttime + 1000 - parseInt(evt.beginsAtEpoch);
      return {
        _id,
        timeDiff
      };
    });
    const sorted = timelineEvents
      .filter((a) => a.timeDiff <= 0)
      .sort((a, b) => parseInt(b.timeDiff) - parseInt(a.timeDiff));
    const nextEvent = sorted[0];

    const eventIndex = nextEvent
      ? _events.findIndex((evt) => evt._id === nextEvent._id)
      : _events.length - 1; // get home_end event if no events found

    const result = _events;

    if (eventIndex > -1) {
      result[eventIndex].isNext = true;
    }
    return result;
  };

  const eventsReducer = (state, action) => {
    switch (action.type) {
      case "rewrite":
        eventsRef.current = action.payload;
        return action.payload;
      case "reset":
        eventsRef.current = [];
        return [];
      default:
        throw new Error();
    }
  };

  const itineraryDTO = useRef(null);
  const eventsRef = useRef([]);
  const [events, eventsDispatch] = useReducer(eventsReducer, []);
  const socket = useRef(null);

  const routeEndNotificationDispatched = useRef(true);

  useEffect(() => {
    if (events && events.length > 0) {
      const lastEventDateTime = parseInt(
        events[events.length - 1].beginsAtEpoch
      );
      if (
        clientDateTime.getTime() >= lastEventDateTime &&
        !routeEndNotificationDispatched.current
      ) {
        toast("🏁 Route Ended! Client time is past last event time.");
        routeEndNotificationDispatched.current = true;
      }
    }
  }, [events, clientDateTime]);

  window.onbeforeunload = () => {
    localStorage.setItem("oldRouteId", routeId);
  };

  const setPreviousCursorLocation = () => {
    const lastCursorLocation = JSON.parse(
      localStorage.getItem("cursorLocation")
    );
    const result = {
      lat: lastCursorLocation[0],
      lng: lastCursorLocation[1]
    };
    setStartLocation(result);
  };

  const loadRoute = (res) => {
    const isRegenerated = JSON.parse(res)?.regenerated;
    const isNew = JSON.parse(res)?.isNew;
    const data = JSON.parse(res)?.data;
    const userOverrides = JSON.parse(res)?.userOverrides;
    let routeIsNew = false;
    let evts = [];
    if (isNew && !isRegenerated && userOverrides && userOverrides.type === "start-location") {
      setClientDateTime(new Date(data.startTime));

      const { lat, lng } = userOverrides.data;
      console.log('setting start location', { lat, lng }, JSON.parse(res))
      setStartLocation({ lat, lng });
      setStartLocationSet(true);
      setStartLocationSet(false);
    }
    if (data) {
      const rid = data._id;
      const oldRouteId = localStorage.getItem("oldRouteId");
      const newRouteId = localStorage.getItem("currentRouteId");
      if (!oldRouteId || oldRouteId.length === 0) {
        localStorage.setItem("oldRouteId", rid);
      }
      if (rid !== newRouteId) {
        // Route is new. Reset parameters and do other logic related to fact
        routeIsNew = true;
        localStorage.setItem("currentRouteId", rid);
        setNewRouteLoaded(true);
        toast("🏁 Loaded new route!");
      } else {
        setPreviousCursorLocation();
        toast("🏁 Loaded last active route!");
      }
      setRouteId(rid);
      setTimeToGenerate(`${parseInt(data.timeToGenerate)}ms`);
      const days = data.days;
      days.forEach((day) => {
        if (day.events) {
          const dayEvents = day.events.map((dayEvt) => ({
            ...dayEvt,
            day: day.day
          }));
          evts = [...evts, ...dayEvents];
        }
      });
      console.log('evts', evts)
      evts = evts
        .filter((evt) => evt.location?.length === 2)
        .map((evt) => {
          const location = [evt.location[1], evt.location[0]];
          return {
            ...evt,
            location
          };
        });
      const homeEndRoundTrip = {
        ...evts[0],
        label: "End",
        locationLabel: "End",
        day: evts[evts.length - 1].day,
        beginsAtEpoch: evts[evts.length - 1].endsAtEpoch,
        endsAtEpoch: evts[evts.length - 1].endsAtEpoch,
        _id: "day_end"
      };
      const readableLocList = evts
        .map((evt, idx) => `${idx + 1}. ${evt.locationLabel}`)
        .join(", \n");
      console.log(readableLocList);
      // evts.push(homeEndRoundTrip);
      if (routeIsNew && !isRegenerated) {
        const time = new Date(parseInt(evts[0].beginsAtEpoch));
        time.setHours(time.getHours());
        time.setMinutes(time.getMinutes());
        setClientDateTime(time);
      }
      eventsDispatch({
        payload: calculateNextEvent(evts, clientDateTime.getTime()),
        type: "rewrite"
      });
    }
    setTimeout(() => setNewRouteLoaded(false), 1000);
  };

  useEffect(() => {
    if (!socket.current) {
      socket.current = io(`${process.env.REACT_APP_SOCKET_IO_URL}`);
      socket.current.on("connect", async (data) => {
        const userId = localStorage.getItem("userId");
        console.log("Socket Connected!");
        socket.current.emit("auth", { userId });
      });
      socket.current.on("itn-error", (data) => {
        console.error(data);
        toast.error(`Server error: ${data.error}`);
      });
      socket.current.on("itn-start-res", (res) => {
        console.log("Starting itinerary!", res);
        loadRoute(itineraryDTO.current);
      });
      socket.current.on("itn-get-latest-res", (res) => {
        loadRoute(res);
      });
      socket.current.on("itn-generated", (res) => {
        const currentRouteId = localStorage.getItem("currentRouteId");
        if (currentRouteId && currentRouteId !== JSON.parse(res)._id) {
          loadRoute(res);
        } else {
          itineraryDTO.current = res;
        }
      });
      socket.current.on("itn-quit-res", (res) => {
        toast("🏁 User quit route!");
        localStorage.removeItem("currentRouteId");
        localStorage.removeItem("oldRouteId");
        eventsDispatch({
          payload: [],
          type: "rewrite"
        });
        setTimeout(() => window.location.reload(), 1000);
      });

      socket.current.on("itn-client-time", (res) => {
        const data = JSON.parse(res);
        const timestamp = parseInt(data.timestamp);
        const newDateTime = handleIncrementDateTime(
          clientDateTimeRef.current.getTime()
        );
        eventsDispatch({
          payload: [...calculateNextEvent(eventsRef.current, newDateTime)],
          type: "rewrite"
        });
      });

      // Useless for now
      // socket.current.on("itn-marked-complete", data => {
      //   console.log('marked complete!', JSON.parse(data));
      // });

      if (loggedIn) {
        const userId = localStorage.getItem("userId");
        socket.current.emit("auth", { userId });
        socket.current.emit("itn-get-latest", { userId });
      }
    }
    const routeEndNotificationTimer = setTimeout(() => {
      routeEndNotificationDispatched.current = false;
    }, 5000);
    return function cleanup() {
      clearTimeout(routeEndNotificationTimer);
    };
  }, []);

  const [routeId, setRouteId] = useState("N/A");
  const [timeToGenerate, setTimeToGenerate] = useState("N/A");

  const handleLogin = async (e) => {
    e.preventDefault();
    const user = await login({ username, password, pushToken });
    if (!user.error) {
      const userId = user.userID;
      socket.current.emit("auth", { userId });
      socket.current.emit("itn-get-latest", { userId });
      setModalIsOpen(false);
      setLoggedIn(true);
    }
  };

  const handleLoginIndicatorClick = (e) => {
    if (!loggedIn) {
      setModalIsOpen(true);
    } else {
      // setEvents([]);
      eventsDispatch({ type: "reset" });
      setRouteId("");
      setTimeToGenerate("");
      logout();
      setLoggedIn(!loggedIn);
    }
  };

  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handlePushTokenChange = (e) => setPushToken(e.target.value);

  useEffect(() => {
    localStorage.setItem("clienttime", `${clientDateTime.getTime()}`);
    clientDateTimeRef.current = clientDateTime;
  }, [clientDateTime]);

  const handleDateTimeChange = (datetime) => {
    setClientDateTime(datetime);
  };

  const handleUpdateLocation = ({ lat, lng }, synthetic) => {
    const userId = localStorage.getItem("userId");
    const pushToken = localStorage.getItem("pushToken");

    let newDateTime = clientDateTimeRef.current.getTime();
    if (!synthetic) {
      newDateTime = handleIncrementDateTime(
        clientDateTimeRef.current.getTime()
      );
      setClientDateTime(new Date(newDateTime));
    }
    emitLocationUpdate({ userId, pushToken, routeId, lat, lng, timestamp: newDateTime, timezone: moment.tz.guess(), });
  };

  const emitLocationUpdate = useCallback(
    throttle((data) => {
      socket.current.emit("itn-location-update", JSON.stringify(data));
    }, 1000),
    []
  );

  const isRouteRegenerated = useRef(false);

  useEffect(() => {
    if (routeId.length > 0) {
      console.log("routeId changed!");
      isRouteRegenerated.current = true;
      setTimeout(() => (isRouteRegenerated.current = false), 15000);
    }
  }, [routeId]);

  const [visitedActivities, setVisitedActivities] = useState([]);

  const handleLocationVisited = useCallback(
    (location) => {
      const userId = localStorage.getItem("userId");
      const { _id, poiID, locationLabel } = location;
      const clientDateTime = clientDateTimeRef.current.getTime();
      // const payload = {
      //   _id,
      //   itineraryId: routeId,
      //   userId,
      //   poiID,
      //   locationLabel,
      //   activity: location,
      //   clientDateTime,
      //   regenerate: false // temporarly off
      //   // regenerate: true
      // };
      const payload = {
        userId,
        routeId,
        activityId: poiID,
        time: clientDateTime,
        action: 'visited'
      }
      const activityVisited = visitedActivities.find((el) => el === _id);
      if (
        _id !== visitedLocationId.current &&
        !isRouteRegenerated.current &&
        !activityVisited
      ) {
        visitedLocationId.current = _id;
        setVisitedActivities((arr) => [...arr, _id]);
        socket.current.emit("itn-location-visited", JSON.stringify(payload));
      } else if (isRouteRegenerated.current && !activityVisited) {
        isRouteRegenerated.current = false;
        visitedLocationId.current = _id;
        setVisitedActivities((arr) => [...arr, _id]);
        socket.current.emit(
          "itn-location-visited",
          JSON.stringify({ ...payload, regenerate: false })
        );
      }
    },
    [visitedActivities, routeId]
  );

  const handleLocationVisitedThrottled = useCallback(
    throttle(handleLocationVisited, 1000),
    [routeId]
  );

  const timeShiftMin = useRef(0);
  const handleIncrementDateTime = useCallback(
    (timestamp) => {
      const newDateTime = timestamp + timeShiftMin.current * 60000;
      return parseInt(newDateTime);
    },
    [timeShiftMin]
  );

  const handleTransportValueChange = (val) => {
    setTransportValue(val);
    const persistValue = TRANSPORT_MODES.find((m) => m.value === val);
    localStorage.setItem("transportMode", JSON.stringify(persistValue));
  };

  const handleDisplayEventInfo = (eventInfo) => {
    setEventInfo(eventInfo);
  };

  const handleDistanceToNext = (distance) => {
    setDistanceToNextActivity(distance);
  };

  const handleStartLocationChange = (data) => {
    const [lat, lng] = data.split(",") || [];
    if (lat && lng) {
      const userId = localStorage.getItem("userId");
      const data = { userId, type: "start-location", data: { lat, lng } };
      socket.current.emit("itn-set-override", JSON.stringify(data));
      setStartLocation({ lat, lng });
      localStorage.setItem("cursorLocation", JSON.stringify([lat, lng]));
      setStartLocationSet(true);
      setTimeout(() => {
        setStartLocationSet(false), 1000;
      });
      toast(
        "📍 Start location override set! Please re-generate route for new changes to take effect."
      );
    } else {
      toast.error(
        "Error setting start location override! Please check your format and make sure it's: lat,lng"
      );
    }
  };

  const parseTimeInput = (data) => {
    const hoursOnly = data.includes(":") ? false : true;
    if (hoursOnly) {
      return data * 60 * 60 * 1000;
    } else {
      const isNegative = data[0] === "-";
      const input = data.split(":");
      const hours = input[0].length > 0 ? parseInt(input[0]) : 0;
      const minutes = input[1].length > 0 ? parseInt(input[1]) : 0;
      const hoursMs = hours * 60 * 60 * 1000;
      const minutesMs = minutes * 60 * 1000;
      const minutesMsFinal = isNegative ? minutesMs * -1 : minutesMs;
      return hoursMs + minutesMsFinal;
    }
  };

  const updateZoomMultiplier = (zoom) => {
    // @ts-ignore
    const item = zoomLevels.find((z) => z.level === zoom).multiplier;
    const result = item === 1 ? item : item * 10;
    zoomMultiplier.current = result;
  };

  // returns milliseconds
  const handleTimeTextInputChange = (data) => {
    const result = parseTimeInput(data) + clientDateTimeRef.current.getTime();
    setClientDateTime(new Date(result));
  };

  const handleMapZoomUpdate = (zoom) => {
    updateZoomMultiplier(zoom);
    const velocity = TRANSPORT_MODES.find((m) => m.value === transportValue)
      .velocity;
    setMetersIncrementFactor(velocity * zoomMultiplier.current);
  };

  const handleToggleShowReadableLocationList = () => {
    setShowReadableLocationList(!showReadableLocationList);
  };

  const handleUpdateTimeShiftMin = (newTimeShift) => {
    timeShiftMin.current = newTimeShift;
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />
      <Modal
        isOpen={startLocationModalActive}
        onRequestClose={closeStartLocationModal}
        style={textInputModalStyles}
        contentLabel="Start location override modal"
      >
        <ModalTextInputComponent
          onModalClose={closeStartLocationModal}
          onSubmit={handleStartLocationChange}
          label="Enter start location in format: lat,lng"
        />
      </Modal>
      <Modal
        isOpen={timeTextInputModalActive}
        onRequestClose={closeTimeTextInputModal}
        style={textInputModalStyles}
        contentLabel="Time modification modal"
      >
        <ModalTextInputComponent
          onModalClose={closeTimeTextInputModal}
          onSubmit={handleTimeTextInputChange}
          label="Enter time"
        />
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Login modal"
      >
        <h2 ref={(_subtitle) => (subtitle.current = _subtitle)}>Sign in</h2>
        <form
          onSubmit={handleLogin}
          style={{ display: "flex", flexDirection: "column", gap: "15px" }}
        >
          <input
            value={username}
            onChange={handleUsernameChange}
            type="text"
            placeholder="Username"
          />
          <input
            value={password}
            onChange={handlePasswordChange}
            type="password"
            placeholder="Password"
          />
          <input
            value={pushToken}
            onChange={handlePushTokenChange}
            type="text"
            placeholder="Push token (optional)"
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button type="submit">Sign in</button>
            <button onClick={closeModal}>X</button>
          </div>
        </form>
      </Modal>
      <EventInfoComponent eventInfo={eventInfo} />
      <ReadableLocationList data={events} display={showReadableLocationList} />
      <HorizontalWrapper>
        <StyledNavbar>
          <div style={{ display: "flex", zIndex: 100, minHeight: "70px" }}>
            <StyledClock
              events={events}
              dateTime={clientDateTime}
              onDateTimeChange={handleDateTimeChange}
            />
            <button onClick={() => handleDateTimeChange(new Date())}>
              Reset clock
            </button>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex" }}>
              <StyledTransportDropdown
                onValueChange={handleTransportValueChange}
                value={transportValue}
              />
              <StyledLoginIndicator
                loggedIn={loggedIn}
                onClick={handleLoginIndicatorClick}
              />
            </div>
            <DistanceInfoComponent distance={distanceToNextActivity} />
          </div>
        </StyledNavbar>
        <VerticalWrapper>
          <StyledCrosshair />
          <StyledMapComponent
            startLocation={startLocation}
            onToggleShowReadableLocationList={
              handleToggleShowReadableLocationList
            }
            onTransportValueChange={handleTransportValueChange}
            metersIncrementFactor={metersIncrementFactor}
            onLocationVisited={handleLocationVisitedThrottled}
            onDisplayEventInfo={handleDisplayEventInfo}
            onStartLocationModalOpen={handleStartLocationModalOpen}
            onTimeTextInputModalOpen={handleTimeTextInputModalOpen}
            onCloseAllModals={handleCloseAllModals}
            onUpdateLocation={handleUpdateLocation}
            onDistanceToNext={handleDistanceToNext}
            onMapZoomUpdate={handleMapZoomUpdate}
            onUpdateTimeShiftMin={handleUpdateTimeShiftMin}
            newRouteLoaded={newRouteLoaded}
            transportMode={transportValue}
            startLocationSet={startLocationSet}
            currentTime={clientDateTime}
            loggedIn={loggedIn}
            events={eventsRef.current}
            routeId={routeId}
          />
        </VerticalWrapper>
        <Footer>
          <h2>ROUTE: {routeId}</h2>
          <h2>GENERATED IN: {timeToGenerate}</h2>
        </Footer>
      </HorizontalWrapper>
    </>
  );
}

const HorizontalWrapper = styled.div`
  display: flex;
  width: 100vw;
`;
const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledNavbar = styled(Navbar)`
  width: ${MAIN_CONTENT_WIDTH}vw;
  max-width: ${MAIN_CONTENT_WIDTH}vw;
  height: ${NAVBAR_HEIGHT}vh;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  padding: 2em 15em;
`;

const StyledClock = styled(Clock)`
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #000;
  padding: 0 2.5em;
  min-width: 180px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTransportDropdown = styled(TransportDropdown)`
  z-index: 100;
  flex: 1;
  background-color: #fff;
  border: 1px solid #000;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLoginIndicator = styled(LoginIndicator)`
  z-index: 100;
  background-color: #fff;
  border: 1px solid #000;
  cursor: pointer;
  user-select: none;
  padding: 0 0.5em;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledMapComponent = styled(MapComponent)`
  width: ${MAIN_CONTENT_WIDTH}vw;
  height: ${MAIN_CONTENT_HEIGHT}vh;
  z-index: 50;
`;

const StyledSidebar = styled(Sidebar)`
  width: ${SIDEBAR_WIDTH}vw;
  height: 100vh;
  background-color: #d3d3d3;
`;

const StyledCrosshair = styled(Crosshair)`
  position: absolute;
  height: ${CROSSHAIR_CONTAINER_SIZE}px;
  width: ${CROSSHAIR_CONTAINER_SIZE}px;
  left: 50%;
  margin-left: -${CROSSHAIR_CONTAINER_OFFSET}px;
  top: 50%;
  margin-top: -${CROSSHAIR_CONTAINER_OFFSET}px;
  z-index: 120;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: ${MAIN_CONTENT_WIDTH}vw;
  h2: {
    color: red;
  }
  box-sizing: border-box;
  padding: 2em 5em;
`;

export default App;
