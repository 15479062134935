import { TRANSPORT_MODES } from "app/constants";
import React from "react";

const TransportDropdown = ({ className, value, onValueChange, children }) => {
  const handleSubmit = () => {};

  const transportationOptions = TRANSPORT_MODES.map((item, idx) => (
    <option key={idx} value={item.value}>
      {item.label}
    </option>
  ));

  const handleChange = (event) => {
    onValueChange(event.target.value);
  };

  const maxDimensions = { width: "100%", height: "100%" };

  return (
    <div className={className}>
      <form style={maxDimensions} onSubmit={handleSubmit}>
        <select
          value={value}
          style={maxDimensions}
          name="transportation"
          onChange={handleChange}
        >
          {transportationOptions}
        </select>
      </form>
    </div>
  );
};

export default TransportDropdown;
