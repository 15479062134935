import React from "react";
import "./DistanceInfoComponent.scss";

function DistanceInfoComponent({ distance }) {
  const outputDistance = distance || "n/a";
  const printOutputDistance = (data) => {
    let output;
    const distance = Math.round(data);
    if (distance >= 1000) {
      output = distance / 1000;
      return `${output} km`;
    } else {
      return `${distance} m`;
    }
  };
  return (
    <div className="distance-info-container">
      <p>
        Distance to next activity: <b>{printOutputDistance(outputDistance)}</b>
      </p>
    </div>
  );
}

export default DistanceInfoComponent;
